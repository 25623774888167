<template>
  <div class="navbar">
    <div class="container flex_jcsb_ac">
      <div class="orderName" @click="goHome">槿兔新纪元系统</div>
      <div class="name">欢迎您，{{ userInfo.username }} <span @click="loginOut">退出</span> </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  methods: {
    goHome() {
      this.$router.replace('/home/index')
    },
    loginOut() {
      this.$message('退出登录成功！')
      localStorage.clear()
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 60px;
  background-color: #fff;
  .container {
    width: 1200px;
    // justify-content: flex-end;
    height: 100%;
    .orderName {
      cursor: pointer;
      font-weight: bold;
      font-size: 28px;
    }
    .name {
        span {
            color: #409EFF;
            margin-left: 10px;
            cursor: pointer;
        }
    }
  }
}
</style>
