<template>
    <div>
       <navbar />
       <div class="container">
        <keep-alive include="Index">
          <router-view></router-view>
        </keep-alive>
       </div>
    </div>
</template>

<script>
import navbar from '../components/Navbar.vue'

export default {
  components: {
    navbar
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 30px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;
}
</style>
